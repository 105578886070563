<template>
  <Page class="page-pricings page-edit-pricing">
    <template #title>Modifica pricing</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'pricings.show' }">Annulla modifica</router-link>
    </template>

    <template #default>
      <PricingForm :feedback.sync="feedback" :pricing="pricingToEdit" @submitForm="submit" edit />
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import PricingForm from '@/views/components/Form/PricingForm.vue';

export default {
  components: {
    Page,
    PricingForm,
  },
  props: {
    pricing: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    pricingToEdit: {},
    feedback: null,
  }),
  watch: {
    pricing (newValue) {
      this.updatePricingToEdit(newValue);
    },
  },
  methods: {
    updatePricingToEdit (pricing) {
      this.pricingToEdit = {
        ...pricing,
        ...this.pricingToEdit,
      };
    },
    submit (pricingToEdit) {
      const pricing = { ...pricingToEdit };

      this.$api.updatePricing(pricing.id, pricing)
        .then(res => {
          this.feedback = true;
          this.$emit('update:pricing', res.data);
          this.pricingToEdit = {};
          this.$router.push({ name: 'pricings.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updatePricingToEdit(this.pricing);
  },
};

</script>
